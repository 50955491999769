<template>
    <div class="avatar-upload">
      <Row>
        <Col span="12" class="upload-section">
          <Upload
            action=""
            :show-upload-list="false"
            :before-upload="handleBeforeUpload"
          >
            <div v-if="cropperSrc" class="image-preview">
              <img :src="cropperSrc" alt="Avatar" class="uploaded-img">
            </div>
            <div v-else class="default-avatar">
              <img :src="defaultAvatar" alt="Default Avatar" class="default-img">
            </div>
            <i-button icon="ios-camera" type="primary" class="upload-button">上传头像</i-button>
          </Upload>
        </Col>
        <Col span="12" class="cropper-section">
          <div v-if="cropperSrc" class="cropper-container">
            <vue-cropper
              ref="cropper"
              :src="cropperSrc"
              :auto-crop="true"
              :fixed-box="false"
              :scalable="true"
              :zoomable="true"
              :view-mode="2"
              :drag-mode="cropDragMode"
              :toggle-drag-mode-on-dblclick="false"
            ></vue-cropper>
            <div class="cropper-buttons">
              <i-button @click="rotateLeft">左旋</i-button>
              <i-button @click="rotateRight">右旋</i-button>
              <i-button @click="clear">清除</i-button>
              <i-button @click="crop">裁剪</i-button>
              <i-button @click="upload">上传</i-button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AvatarUpload',
    components: {
    },
    props: {
      defaultAvatar: String,
      otherParams: Object,
    },
    data() {
      return {
        cropperSrc: null,
        croppedImage: null,
        cropDragMode: 'move',
      };
    },
    methods: {
      handleBeforeUpload(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            console.log('文件上传成功', e.target.result);
          this.cropperSrc = URL.createObjectURL(file)
        };
        reader.readAsDataURL(file);
        return false; // 阻止文件上传，以便预览
      },
      rotateLeft() {
        this.$refs.cropper.getCropper().rotate(-90);
      },
      rotateRight() {
        this.$refs.cropper.getCropper().rotate(90);
      },
      clear() {
        this.cropperSrc = null;
        this.croppedImage = null;
      },
      crop() {
        const cropper = this.$refs.cropper.getCropper();
        this.croppedImage = cropper.getCroppedCanvas().toDataURL();
        // 在此处可以显示裁剪后的图片
        console.log(this.croppedImage);
      },
      upload() {
        if (this.croppedImage) {
          axios.post('/upload-avatar', { image: this.croppedImage })
            .then(response => {
              console.log('上传成功', response.data);
              // 处理上传成功后的逻辑
            })
            .catch(error => {
              console.error('上传失败', error);
              // 处理上传失败后的逻辑
            });
        } else {
          console.error('请先裁剪图片');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .avatar-upload {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-section, .cropper-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .uploaded-img, .default-img {
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #ccc;
  }
  
  .default-avatar {
    width: 150px;
    height: 150px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 50%;
  }
  
  .upload-button {
    margin-top: 10px;
  }
  
  .cropper-container {
    margin-top: 20px;
    border: 2px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    height: 300px;
    position: relative;
  }
  
  .cropper-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
  
  .cropper-buttons i-button {
    flex: 1;
    margin: 0 5px;
  }
  </style>
  