<template>
  <div class="user-profile">
    <Card class="user-card">
      <div class="user-info">
        <div @click="showAvatarPreview">
          <Avatar :size="80" :src="user.avatar" class="user-avatar"></Avatar>
        </div>
        <div class="user-details">
          <h2>{{ user.name }}</h2>
          <p>{{ user.email }}</p>
          <Tag color="blue">{{ user.role }}</Tag>
          <div v-if="user.status" class="status">{{ user.status }}</div>

        </div>
      </div>
    </Card>

    <Modal
      v-model="avatarVisible"
      title="预览头像"
      @ok="confirmAvatar"
      @cancel="avatarVisible = false"
    >
    <avatar-upload :default-avatar="user.avatar" ></avatar-upload>

    </Modal>

    <Tabs type="card" class="user-tabs">
      <TabPane label="个人信息">
        <Form :model="user" :label-width="80">
          <FormItem label="姓名">
            <Input v-model="user.name"></Input>
          </FormItem>
          <FormItem label="邮箱">
            <Input v-model="user.email"></Input>
          </FormItem>
          <FormItem label="角色">
            <Input v-model="user.role"></Input>
          </FormItem>
          <FormItem label="状态">
            <Input v-model="user.status"></Input>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="updateUserInfo">更新信息</Button>
          </FormItem>
        </Form>
      </TabPane>

      <TabPane label="修改密码">
        <Form :model="passwordForm" :label-width="80">
          <FormItem label="旧密码">
            <Input v-model="passwordForm.oldPassword" type="password"></Input>
          </FormItem>
          <FormItem label="新密码">
            <Input v-model="passwordForm.newPassword" type="password"></Input>
          </FormItem>
          <FormItem label="确认密码">
            <Input
              v-model="passwordForm.confirmPassword"
              type="password"
            ></Input>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="changePassword">修改密码</Button>
          </FormItem>
        </Form>
      </TabPane>

      <TabPane label="粉丝列表">
        <Table :columns="userColumns" :data="followers">
          <template slot-scope="{ row }">
            <Avatar
              :src="row.avatar"
              size="small"
              style="margin-right: 8px"
            ></Avatar>
            {{ row.name }}
            <Tag
              :color="row.status === '在线' ? 'green' : 'gray'"
              style="margin-left: 8px"
              >{{ row.status }}</Tag
            >
            <Button
              type="text"
              @click="followUser(row.id)"
              style="float: right"
              >{{ row.following ? "取消关注" : "关注" }}</Button
            >
          </template>
        </Table>
      </TabPane>

      <TabPane label="关注列表">
        <Table :columns="userColumns" :data="following">
          <template slot-scope="{ row }">
            <Avatar
              :src="row.avatar"
              size="small"
              style="margin-right: 8px"
            ></Avatar>
            {{ row.name }}
            <Tag
              :color="row.status === '在线' ? 'green' : 'gray'"
              style="margin-left: 8px"
              >{{ row.status }}</Tag
            >
            <Button
              type="text"
              @click="unfollowUser(row.id)"
              style="float: right"
              >取消关注</Button
            >
          </template>
        </Table>
      </TabPane>

      <TabPane label="动态">
        <DiaryList></DiaryList>
      </TabPane>
    </Tabs>
  </div>
</template>
  
  <script>
import AvatarUpload from '@/components/AvatarUpload.vue';
import DiaryList from '@/components/userHome/DiaryList.vue';
export default {
  data() {
    return {
      user: {
        avatar: "https://w.wallhaven.cc/full/qz/wallhaven-qzq7dr.jpg",
        name: "张三",
        email: "zhangsan@example.com",
        role: "管理员",
        status: "在线",
      },
      avatarVisible: false,
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      followers: [
        {
          id: "001",
          name: "李四",
          avatar: "https://w.wallhaven.cc/full/qz/wallhaven-qzq7dr.jpg",
          status: "在线",
          following: true,
        },
        {
          id: "002",
          name: "王五",
          avatar: "https://w.wallhaven.cc/full/qz/wallhaven-qzq7dr.jpg",
          status: "离线",
          following: false,
        },
        // 添加更多粉丝
      ],
      following: [
        {
          id: "101",
          name: "赵六",
          avatar: "https://w.wallhaven.cc/full/qz/wallhaven-qzq7dr.jpg",
          status: "在线",
        },
        {
          id: "102",
          name: "孙七",
          avatar: "https://w.wallhaven.cc/full/qz/wallhaven-qzq7dr.jpg",
          status: "离线",
        },
        // 添加更多关注
      ],
      activities: [
        {
          id: "001",
          content: "张三刚刚发布了一条新动态。",
          time: "2023-01-01 10:00",
        },
        {
          id: "002",
          content: "张三点赞了李四的动态。",
          time: "2023-01-02 11:00",
        },
        // 添加更多动态
      ],
      userColumns: [
        { title: "用户名", key: "name" },
        { title: "状态", key: "status" },
      ],
    };
  },
  components: {
    AvatarUpload,
    DiaryList
  },
  methods: {
    showAvatarPreview() {
      this.avatarVisible = true;
    },
    confirmAvatar() {
      this.$refs.cropper.getCropBlob((data) => {
        this.user.avatar = URL.createObjectURL(data);
        this.avatarVisible = false;
      });
    },
    updateUserInfo() {
      this.$Message.success("用户信息已更新！");
    },
    changePassword() {
      if (this.passwordForm.newPassword !== this.passwordForm.confirmPassword) {
        this.$Message.error("两次输入的新密码不一致！");
        return;
      }
      this.$Message.success("密码已修改！");
    },
    followUser(id) {
      const user = this.followers.find((user) => user.id === id);
      if (user) {
        user.following = !user.following;
        this.$Message.success(
          user.following ? "已关注该用户！" : "已取消关注该用户！"
        );
      }
    },
    unfollowUser(id) {
      const user = this.following.find((user) => user.id === id);
      if (user) {
        this.following = this.following.filter((user) => user.id !== id);
        this.$Message.success("已取消关注该用户！");
      }
    },
    likeActivity(id) {
      this.$Message.success("已点赞该动态！");
    },
    commentActivity(id) {
      this.$Message.success("评论功能未实现");
    },
  },
};
</script>
  
  <style scoped>
.user-profile {
  padding: 20px;
  background-color: #f0f2f5;
}

.user-card {
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-avatar {
  border: 2px solid #1890ff;
  cursor: pointer;
}

.user-details {
  margin-left: 20px;
}

.user-details h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.user-details p {
  margin: 5px 0;
}

.user-details .ivu-tag {
  margin-top: 10px;
}

.user-tabs {
  background-color: #fff;
  padding: 20px;
}

.activity-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.activity-item .activity-actions {
  display: flex;
  gap: 10px;
}

.status {
  color: #4CAF50;
  font-weight: bold;
  margin-top: 5px;
}
</style>